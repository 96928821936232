<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <ASuccessWithoutReload :api="this.api" @close="fetch()"/>
        <v-data-table
            v-if="!api.isLoading"
            :search="search"
            :items="data"
            :headers="dataHeader"
            class="elevation-1">
            <template
                v-slot:item.index="{item}">
                {{ data.indexOf(item)+1 }}
            </template>
            <template
                v-slot:top>
                <v-toolbar
                    flat 
                    class="text-h4 pt-4"
                    height="auto">
                    <v-row no-gutters>
                        <v-col class="mt-3 mx-3" >
                            <v-row class="mb-3">
                                <v-toolbar-title class="font-weight-bold text-h4 my-3">
                                    Staff list email matched ( Excel )
                                </v-toolbar-title>
                                <v-spacer>
                                </v-spacer>
                                <v-btn color="primary" @click="fetch()"  class="mx-3 mt-3">
                                    Refresh
                                </v-btn> 
                                <v-btn
                                    :loading="api.isLoading"
                                    color="primary" @click="transfer()">
                                    transfer
                                </v-btn>
                                <JsonCSV :data="data" class="mx-2 mt-1">
                                    <v-btn color="primary">
                                        Download
                                    </v-btn>
                                </JsonCSV>

                            </v-row>    
                            <v-row>
                                <v-text-field
                                    v-model="search"
                                    dense 
                                    outlined 
                                    label="Search">
                                </v-text-field>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../common/ASuccessWithoutReload.vue';
export default {
    components:{
    ASuccessWithoutReload
},
    computed: mapState({
    //
}),
    props:[
    //
    ],
    data: () => ({
        expanded:[],
        search:"",
        data:null,
        dataHeader:[
            {
                text:'#',
                value:'index',
            },
            {
                text:'Current company id',
                value:'current_company'
            },
            {
                text:'User database',
                value:'user_name',
            },
            {
                text:'User excel',
                value:'excel_user_name',
            },
            {
                text:'Email database',
                value:'user_email',
            },
            {
                text:'Email excel',
                value:'excel_email'
            },
            {
                text:'Mobile database',
                value:'user_mobile',
            },
            {
                text:'Mobile excel',
                value:'excel_mobile'  
            },
            // {
            //     text:'Subscription type in database',
            //     value:'subcription_type'
            // }, 
            // {
            //     text:'Branch from excel',
            //     value:'excel_branch',
            // },
            // {
            //     text:'Branch from database',
            //     value:'staff_branch',
            // },
            // {
            //     text:'Position from excel',
            //     value:'excel_position',
            // },
            // {
            //     text:"Position from database",
            //     value:'staff_position'
            // },
           
            // {
            //     text:'User type',
            //     value:'user_type'
            // },
            {
                text:'Remark',
                value:'remark',
            },
            {
                text:'Remark from database',
                value:'user_remark',
            }
        ],
        AAERole:[
        'client',
        'consultant',
        'tax_expert',
        'admin',
        'manager',
        ],
        VisionRole:[
        'salesperson',
        'customer_service',
        'sales_team_lead',
        'vision_admin',
        'operation',
        'sales_manager',
        'developer',
        'marketing',
        ],
        options:{
            itemsPerPage:20,
            page:1,
            sortBy:[],
            search:'',
            couponSearch:'',
        },
        isFirstLoading:true,
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="fixStaffListUsingExcel") {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully fix the staff list";
            }
            if(resp.class==="getExcelMatchedStaffList") {
                    this.data = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchStaffListApi = this.fetchStaffList();
            this.$api.fetch(fetchStaffListApi);
        },
        fetchStaffList() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/staff_list/matched";
            return tempApi;
        },
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        transfer() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/staff_list/fix/"+this.$store.getters.getUserId;
            this.$api.fetch(tempApi);
        },  
     
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL');
        },
    }
}
</script>